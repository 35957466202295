<template>
  <div>
    <v-row>
      <v-col>
        <h3>
          Para validar o WhatsApp do seus {{ customerAlias }}s, você deve seguir
          os seguintes passos:
        </h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Paso 1</h4>
        <v-img
          width="60%"
          src="@/assets/images/ajuda/validacao_whatsApp/step1.jpeg"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Passo 2</h4>
        <v-img
          width="60%"
          src="@/assets/images/ajuda/validacao_whatsApp/step2.jpeg"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h4>Resumo</h4>
        <v-img
          width="60%"
          src="@/assets/images/ajuda/validacao_whatsApp/step3.jpeg"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <a :href="getHelp">
          Precisando de ajuda? entre em contato com nosso suporte</a
        >.
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      customerAlias: customerAlias(),
    };
  },
  computed: {
    getHelp() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_SUPPORT_WHATSAPP}`;

      let msg = "&text=Olá, Preciso de ajuda!";

      return link + msg;
    },
  },
};
</script>

<style>
</style>